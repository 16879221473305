<div #scrollTop fxLayout="row"></div>
<div style="z-index:5; position : fixed;top : 50% ;right:2%" >
  <a mat-fab color="primary" (click) =
 "showBottomSheet()"> <mat-icon>info</mat-icon></a>
</div>
<div>
<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" *ngFor="let image of selectedGalleryItem.images">
  <div fxFlex="100">
    <img mat-card-image src="{{image}}" class="galleryImage"/>
  </div>
</div>
</div>

