import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {ImageDescriptionComponent} from '../image-description/image-description.component';
import galleryConfig from 'src/assets/config/gallery.json';
import {GalleryItem} from '../model/gallery-item';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-gallery-view',
  templateUrl: './gallery-view.component.html',
  styleUrls: ['./gallery-view.component.css']
})
export class GalleryViewComponent implements OnInit, AfterViewInit {

   selectedGalleryItem: GalleryItem;
   projId: string;
  @ViewChild('scrollTop') scrollTop: ElementRef;
  constructor(private bottomSheet: MatBottomSheet, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.projId = this.route.snapshot.queryParamMap.get('projectId');
    const filteredArray = galleryConfig.galleryItems.filter((galleryItem: GalleryItem) => {
         return  galleryItem.id === this.projId;
    });
    this.selectedGalleryItem = filteredArray.length > 0 ? filteredArray[0] : galleryConfig.galleryItems[0];
  }

  ngAfterViewInit() {
   // this.scrollTop.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  showBottomSheet(){
    this.bottomSheet.open(ImageDescriptionComponent, {
      data: {galleryItem: this.selectedGalleryItem} , hasBackdrop: true,
      panelClass: 'description'
    });
  }

}
