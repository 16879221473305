import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{

  mobileView: boolean;

  constructor(public breakpointObserver: BreakpointObserver, private router: Router) {
  }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(min-width: 500px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.mobileView = false;
          console.log('Viewport is 500px or over!');
        } else {
          this.mobileView = true;
        }
      });
  }

  openHome(){
    this.router.navigate(['/portfolio']);
  }

}
