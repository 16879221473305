<div class="spacing"></div>
<div fxLayout = "row">
  <div class="header" fxFlexOffset="10" fxFlexOffset.xs="10">
    <p><span class="cyan-text">Price </span>Guidelines<br>
      for <span class="cyan-text"> Graphic Design </span> <br>Services
    </p>
  </div>
</div>
<div class="spacing"></div>
<div fxLayout = "row">
  <p fxFlexOffset="10" fxFlex="60" fxFlex.xs="80" fxFlexOffset.xs="10" class="description-text"> Clients will receive production-ready files upon project completion. Any additional files (master) can be provided upon request. An invoice will be issued in compliance with EU laws for the services requested by the client. The 'VAT reverse charge EU' mechanism will naturally apply to the invoice, with the customer being responsible for reporting and remitting the VAT directly to the tax authorities.
  </p>
</div>
<div fxLayout="row">
  <div class="underline" fxFlexOffset="10" fxFlexOffset.xs="10" fxFlex="82"></div>
</div>
<ng-container *ngFor="let price of prices">
  <div fxLayout = "row">
    <div class="info-text" fxFlexOffset.xs="10" fxFlexOffset="10" fxFlex.xs="90">{{price.header}}
    </div>
  </div>
  <div class="blank-row"></div>
  <div fxLayout = "row">
    <div fxFlexOffset="10" fxFlexOffset.xs="10" fxFlex.xs="90" [innerHtml]="price.description | safe: 'html'" class="description-text"></div>
  </div>
  <div fxLayout="row" >
    <div class="underline" fxFlexOffset.xs="10" fxFlexOffset="10" fxFlex="82"></div>
  </div>
</ng-container>
