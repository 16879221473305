import { Component, OnInit } from '@angular/core';
import previewItems from 'src/assets/config/preview.json';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {

  previewItems = previewItems;

  constructor() { }

  ngOnInit(): void {
  }

}
