import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AboutMeComponent} from './about-me/about-me.component';
import {PortfolioComponent} from './portfolio/portfolio.component';
import {GalleryViewComponent} from './gallery-view/gallery-view.component';
import {ContactComponent} from './contact/contact.component';
import {PricesComponent} from './prices/prices.component';


const routes: Routes = [
  { path: 'about', component: AboutMeComponent },
  { path: 'portfolio', component: PortfolioComponent },
  { path: 'gallery', component: GalleryViewComponent },
  { path: 'gallery/:projectId', component: GalleryViewComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'prices', component: PricesComponent },
  { path: '',
    redirectTo: '/portfolio',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
