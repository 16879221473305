import { Component, OnInit } from '@angular/core';
import prices from 'src/assets/config/prices.json';

@Component({
  selector: 'app-prices',
  templateUrl: './prices.component.html',
  styleUrls: ['./prices.component.css']
})
export class PricesComponent implements OnInit {

  prices = prices;

  constructor() { }

  ngOnInit(): void {
  }

}
