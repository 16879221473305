<div fxLayout="row" class="dark">
  <div fxFlex="60" fxFlex.xs="80" fxFlexOffset="10">
    <mat-card [class.mat-elevation-z0]="true" class="dark">
      <mat-card-content>
        <div class="spacing"></div>
        <div fxLayout = "row">
          <div class="header">
            Creative <span class="cyan-text">Graphic <br>Designers</span>
        are <br>problem solvers.<br>
        </div>
        </div>
        <div class="spacing"></div>
        <div fxLayout= "row">
          <div fxFlex="100">
            <p class="description-text">
              I'm <span class="cyan-text"> Anastassija Ramamurthy</span>, a freelance graphic designer in Amsterdam. After graduating as a media designer, I worked in-house for B2B companies in Germany, where I refined my skills in adhering to brand guidelines to create on-target designs.<br>
              After 10 years, I am still passionate about applying my skills in applied Graphic, Print, and Digital Design. My expertise includes packaging design, print materials, logo creation, infographics, booth design, interface design, and the development of brand guidelines. I am always eager to take on new challenges, I collaborate effectively within teams and work independently, while keeping a keen interest in trends and technologies.

            </p>
          </div>
        </div>
        <div class="spacing"></div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div fxLayout="row">
  <div fxFlex="100" fxFlexOffset="10">
    <mat-card [class.mat-elevation-z0]="true">
      <mat-card-content>
        <div class="spacing"></div>
        <div fxLayout = "row" class="header">
          <div fxFlex="100">
            Services offered
          </div>
        </div>
        <div class="blank-row"></div>
        <div class="blank-row"></div>
        <div fxLayout = "row wrap" fxLayout.xs="column" fxFLexWrap>
          <div fxFlex="25" fxFlex.sm="45" fxFlex.md="45">
            <div class="services-container services-border-1" fxFlex="100">
              <div fxLayout = "row"  class="header-text">Branding</div>
              <mat-list role="list">
                <mat-list-item role="listitem" class="services-text"><mat-icon matListIcon>star_outline</mat-icon> Logo & Logotype Design</mat-list-item>
                <mat-list-item role="listitem" class="services-text"> <mat-icon matListIcon>star_outline</mat-icon> Business Card</mat-list-item>
                <mat-list-item role="listitem" class="services-text"><mat-icon matListIcon>star_outline</mat-icon> Letterhead</mat-list-item>
                <mat-list-item role="listitem" class="services-text"><mat-icon matListIcon>star_outline</mat-icon> Envelope</mat-list-item>
                <mat-list-item role="listitem" class="services-text"><mat-icon matListIcon>star_outline</mat-icon> Brand Guidelines</mat-list-item>
                <mat-list-item role="listitem" class="services-text"></mat-list-item>
              </mat-list>
              </div>
          </div>
          <div fxFlex="25" fxFlexOffset="5" fxFlex.sm="45" fxFlex.md="45">
            <div class="services-container services-border-3">
              <div fxLayout = "row" class="header-text">Marketing</div>
              <mat-list role="list">
                <mat-list-item role="listitem" class="services-text">
                  <mat-icon matListIcon>star_outline</mat-icon>
                  Brochure & Magazine</mat-list-item>
                <mat-list-item role="listitem" class="services-text"> <mat-icon matListIcon>star_outline</mat-icon> Print Advertisement</mat-list-item>
                <mat-list-item role="listitem" class="services-text"> <mat-icon matListIcon>star_outline</mat-icon> Invitation Card</mat-list-item>
                <mat-list-item role="listitem" class="services-text"> <mat-icon matListIcon>star_outline</mat-icon> Trade Show & Event</mat-list-item>
                <mat-list-item role="listitem" class="services-text"> <mat-icon matListIcon>star_outline</mat-icon> Packaging Design</mat-list-item>
                <mat-list-item role="listitem" class="services-text"> </mat-list-item>
              </mat-list>
            </div>
          </div>
        <div fxFlex="25" fxFlexOffset="5" fxFlexOffset.sm="0" fxFlexOffset.md="0" fxFlex.sm="45" fxFlex.md="45">
            <div class="services-container services-border-2">
            <div fxLayout = "row"  class="header-text">Digital</div>
              <mat-list role="list">
              <mat-list-item role="listitem" class="services-text"> <mat-icon matListIcon>star_outline</mat-icon> Screen Design</mat-list-item>
              <mat-list-item role="listitem" class="services-text"> <mat-icon matListIcon>star_outline</mat-icon> Web Banner Ad</mat-list-item>
              <mat-list-item role="listitem" class="services-text"> <mat-icon matListIcon>star_outline</mat-icon> Splash Screen</mat-list-item>
                <mat-list-item role="listitem" class="services-text"> <mat-icon matListIcon>star_outline</mat-icon> Interactive Mock</mat-list-item>
                <mat-list-item role="listitem" class="services-text"> <mat-icon matListIcon>star_outline</mat-icon> Image Processing</mat-list-item>
                <mat-list-item role="listitem" class="services-text"></mat-list-item>
              </mat-list>
            </div>
          </div>
          <div fxFlex="25" fxFlexOffset="0" fxFlexOffset.sm="5" fxFlexOffset.md="5" fxFlex.sm="45" fxFlex.md="45">
            <div class="services-container services-border-4">
              <div fxLayout = "row"  class="header-text">Illustration</div>
              <mat-list role="list">
                <mat-list-item role="listitem" class="services-text"><mat-icon matListIcon>star_outline</mat-icon> Infographic</mat-list-item>
                <mat-list-item role="listitem" class="services-text"><mat-icon matListIcon>star_outline</mat-icon> Icon</mat-list-item>
                <mat-list-item role="listitem" class="services-text"><mat-icon matListIcon>star_outline</mat-icon> Symbol</mat-list-item>
                <mat-list-item role="listitem" class="services-text"><mat-icon matListIcon>star_outline</mat-icon> Pictogram</mat-list-item>
                <mat-list-item role="listitem" class="services-text"><mat-icon matListIcon>star_outline</mat-icon> Pattern</mat-list-item>
                <mat-list-item role="listitem" class="services-text"></mat-list-item>
              </mat-list>
            </div>
          </div>
        </div>
        <!--<div fxLayout = "row">

        </div>-->

        <div class="spacing"></div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div fxLayout="row" class="dark">
  <div fxFlex="33" fxFlex.sm="70" fxFlex.md="50" fxFlex.xs="90" fxFlexOffset="10">
    <mat-card [class.mat-elevation-z0]="true" class="dark">
      <mat-card-content>
        <div class="spacing"></div>
        <div fxLayout = "row">
          <div class="header">
            My Expertise is built on
            <span class="cyan-text"> 10 years of Experience.</span>
          </div>
        </div>
        <div class="blank-row"></div>
        <div class="blank-row"></div>
        <div fxLayout= "row">
          <div fxFlex="100">
            <button mat-fab color="primary"  matTooltip="Adobe Photoshop"
                     #tooltip1="matTooltip"  [matTooltipPosition]="'above'"  class="cyan-btn" (click)="tooltip1.toggle()" >
              Ps
            </button>
            <button mat-fab color="primary" matTooltip="Adobe Illustrator"
                    #tooltip2="matTooltip" [matTooltipPosition]="'above'" class="cyan-btn" (click)="tooltip2.toggle()" >
              Ai
            </button>
            <button #tooltip3="matTooltip" mat-fab color="primary" matTooltip="Adobe InDesign"
                     [matTooltipPosition]="'above'" class="cyan-btn" (click)="tooltip3.toggle()" >
              Id
            </button>
            <button mat-fab color="primary" matTooltip="Adobe Dimension"
                    #tooltip4="matTooltip"  [matTooltipPosition]="'above'" class="cyan-btn" (click)="tooltip4.toggle()" >
              Dn
            </button>

            <button mat-fab color="primary" matTooltip="Adobe Experience Design"
                    #tooltip6="matTooltip" [matTooltipPosition]="'above'" class="cyan-btn" (click)="tooltip6.toggle()">
              Xd
            </button>
            <button mat-fab color="primary" matTooltip="Adobe Acrobat" #tooltip5="matTooltip"
                    [matTooltipPosition]="'above'" class="cyan-btn" (click)="tooltip5.toggle()">
              A
            </button>
            <button mat-fab color="primary" matTooltip="Content Management System"
                    #tooltip7="matTooltip" [matTooltipPosition]="'above'" class="cyan-btn"  (click)="tooltip7.toggle()">
              CMS
            </button>
          </div>
        </div>
        <div class="spacing"></div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div fxLayout="row">
  <div fxFlex="100" fxFlexOffset="10">
    <mat-card [class.mat-elevation-z0]="true">
      <mat-card-content>
        <div class="spacing"></div>
        <div fxLayout = "row" class="header" fxLayout.xs="column" fxLayout.sm="column">
          <div fxFlex="50" >
            Work Experience
          </div>
        </div>
        <div class="spacing"></div>
        <div fxLayout = "row" fxLayout.xs="column" fxLayout.sm="column">
          <div fxFlex="30" class="resume-description">
            <p>Freelance Graphic Designer<br><span class="workplace-text">working with various clients based in Germany<br><br>04/2017 - to present</span></p>
          </div>
          <div fxFlexOffset="6" fxFlexOffset.xs="0" fxFlex="55" fxFlexOffset.sm="0" class="resume-text">
            <p> Packaging Design, Designing brochures, flyers, icons, logos, infographics, booths, standing banners, advertisements, menu cards, interface design, image retouching, color adjustment, illustrations, and brand guidelines </p>
          </div>
        </div>
        <div fxLayout="row" >
          <div fxFlex="91" class="underline"></div>

        </div>
        <div fxLayout = "row" fxLayout.xs="column" fxLayout.sm="column">
          <div fxFlex="30"  fxFlex.sm="100" fxFlex.xs="100" class="resume-description">
            <p>Marketing Graphic Designer<br><span class="workplace-text">medavis GmbH <br>Karlsruhe, Germany<br><br>01/2013 - 03/2017</span></p>
          </div>
          <div fxFlexOffset="6" fxFlexOffset.xs="0" fxFlex="55" fxFlexOffset.sm="0" class="resume-text">
            <p> Responsible for print and digital media, encompassing planning, conceptual design development, production, and printing/publishing of regular corporate communication products in accordance with CI specifications. Also, proficient in event management.</p>
          </div>
        </div>
        <div fxLayout="row" >
           <div fxFlex="91" class="underline"></div>
        </div>
        <div fxLayout = "row" fxLayout.xs="column" fxLayout.sm="column">
          <div fxFlex="30" class="resume-description"  fxFlex.sm="100" fxFlex.xs="100">
            <p>Creative Art Manager Tradings<br><span class="workplace-text">UNIWHEELS <br>Holding (Germany) GmbH
              <br><br>08/2011 - 10/2012</span></p>
          </div>
          <div fxFlexOffset="6" fxFlexOffset.xs="0" fxFlexOffset.sm="0" fxFlex="55" class="resume-text">
            <p>Adapting sales communication materials (e.g., price lists, brochures, flyers, etc.) for diverse international markets in alignment with CI specifications, skilled in professional image processing, creating and enhancing PowerPoint presentations, and organizing trade fairs both in Germany and abroad.</p>
          </div>
        </div>
        <div fxLayout="row">
          <div fxFlex="91" class="underline"></div>

        </div>
        <div fxLayout = "row" fxLayout.xs="column" fxLayout.sm="column">
          <div fxFlex="30" class="resume-description"  fxFlex.sm="100" fxFlex.xs="100">
            <p>Communication Designer<br><span class="workplace-text">JUNG:KOMMUNIKATION Stuttgart, Germany<br><br>03/2010 - 08/2010</span></p>
          </div>
          <div fxFlexOffset="6" fxFlexOffset.xs="0"  fxFlexOffset.sm="0" fxFlex="55" class="resume-text">
            <p> Internship during bachelor's degree: Layout design/adaptation, conceptualization and design of visual trademarks, image clipping, and the development of presentations.</p>
          </div>
        </div>
        <div fxLayout="row">
          <div fxFlex="91" class="underline"></div>

        </div>
        <div fxLayout = "row" fxLayout.xs="column" fxLayout.sm="column">
          <div fxFlex="30" class="resume-description"  fxFlex.sm="100" fxFlex.xs="100">
            <p>Graphic Designer <br><span class="workplace-text">HAASE & KNELS + SCHWEERS<br> Bremen, Germany<br><br>07/2009 - 09/2009</span></p>
          </div>
          <div fxFlexOffset="6" fxFlexOffset.xs="0"  fxFlexOffset.sm="0" fxFlex="55" class="resume-text">
            <p> Summer vacation internship responsibilities included: developing corporate design concepts, designing advertisements, posters, and flyers, creating web designs, designing packaging, and retouching images.</p>
          </div>
        </div>
        <div fxLayout="row">
          <div fxFlex="91" class="underline"></div>

        </div>
        <div fxLayout="row">
        <div fxFlex="40" fxFlex.xs="100" fxFlexOffset.xs="0" fxFlexOffset.sm="0">
          <a mat-button color="primary" href="./assets/images/Anastassija_Ramamurthy_Resume.pdf" class="black-btn" download>
            Download Resume
          </a>
        </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

