<div class="spacing"></div>
<div fxLayout = "row">
  <div class="header" fxFlexOffset="10">

    <span >Design Portfolio</span><br>
  </div>
</div>
<div class="spacing"></div>
<div fxLayout = "row">
  <p fxFlexOffset="10" fxFlex="25" fxFlex.xs="80" fxFlexOffset.xs="10" class="description-text"> I'm an experienced graphic designer, providing support to various companies for their print and digital marketing projects.
  </p>
</div>
<div class="spacing"></div>
<ng-container *ngFor="let previewItem of previewItems">
<div fxLayout="row" fxLayout.xs="column"  fxLayout.sm="column" fxLayoutGap="0px" *ngIf="previewItem.flexOrder === 1">
  <div fxFlex="50">
    <img src="{{previewItem.imageSrc}}" fxFlexFill style="object-fit: cover"/>
  </div>
  <div fxFlex="50" class="preview" fxLayoutAlign="center center">
    <p>
       <span class="preview-header" [innerHtml]="previewItem.title | safe: 'html'">
    </span>
      <span class="trademark" *ngIf="previewItem.trademark" [innerHtml]="previewItem.trademark | safe: 'html'"></span>
      <br><br><br>
      <span class="preview-text" [innerHtml]="previewItem.description | safe: 'html'">
    </span>
      <br><br><br>
    <a mat-flat-button style="font-weight:100; font-size:16px" class="no-hover-effect portfolio-button" [routerLink]="['/gallery']" [queryParams]="{projectId: previewItem.id}">View projects</a>
    </p>
  </div>
</div>
<div fxLayout="row" fxLayout.xs="column"  fxLayout.sm="column" style="margin-top:-1px;" *ngIf="previewItem.flexOrder === 2">
  <div fxFlex="50" fxLayoutAlign="center center" fxFlexOrder.xs="2"  fxFlexOrder.sm="2" class="preview">
    <p>
       <span class="preview-header">
       {{previewItem.title}}  <br><br><br>
    </span>
      <span class="preview-text" [innerHtml]="previewItem.description | safe: 'html'">
    </span>
      <br><br><br>
      <a mat-flat-button style="font-weight:100; font-size:16px" class="no-hover-effect portfolio-button" [routerLink]="['/gallery']" [queryParams]="{projectId: previewItem.id}">View projects</a>
    </p>
  </div>
  <div fxFlex="50" fxFlexOrder.xs="-1"  fxFlexOrder.sm="-1">
    <img src="{{previewItem.imageSrc}}" fxFlexFill style="object-fit: cover"/>
  </div>
</div>
</ng-container>
