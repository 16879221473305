<div class="spacing"></div>
<div fxLayout="row" fxLayout.xs="column">
          <div class="contact-text" fxFlex="90" fxFlexOffset="10" >
            <div fxLayout="row">
              <span class="header-text" fxflex="80" fxFlexOffset.xs="10">Get in touch</span>
            </div>
            <div class="blank-row"></div>
           <div fxLayout="row">
             <span  fxflex="80"
                    fxFlexOffset.xs="10" >+31638588780</span>
            </div>
            <div class="blank-row"></div>
            <div fxLayout="row">
              <span  fxflex="80" fxFlexOffset.xs="10">anastassija.ramamurthy@gmail.com</span>
            </div>
            <div class="blank-row"></div>
            <div class="blank-row"></div>
            <div class="blank-row"></div>
            <div fxLayout="row">
              <span  fxflex="80" fxFlexOffset.xs="10">              <a href="https://www.linkedin.com/in/anastassija-ramamurthy-17352a33/" target="_blank" class="link-text">LinkedIn</a> / <a href="https://www.instagram.com/anastassija.ramamurthy" target="_blank" class="link-text">Instagram </a>
              </span>
            </div>
          </div>
</div>
<div fxLayout="column">
  <img mat-card-image src="./assets/images/contact_bild.png" class="contact-image"/>
</div>
