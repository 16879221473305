import {Component, Inject, OnInit} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import {GalleryItem} from '../model/gallery-item';

@Component({
  selector: 'app-image-description',
  templateUrl: './image-description.component.html',
  styleUrls: ['./image-description.component.css']
})
export class ImageDescriptionComponent implements OnInit {

  galleryItem: GalleryItem;

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any ) { }

  ngOnInit(): void {
    this.galleryItem = this.data.galleryItem;
  }

}
