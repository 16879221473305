import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatCardModule} from '@angular/material/card';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatGridListModule} from '@angular/material/grid-list';
import {NgxMasonryModule} from 'ngx-masonry';
import { GalleryViewComponent } from './gallery-view/gallery-view.component';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import { ImageDescriptionComponent } from './image-description/image-description.component';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import { AboutMeComponent } from './about-me/about-me.component';
import {MatListModule} from '@angular/material/list';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { AppRoutingModule } from './app-routing.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import { ContactComponent } from './contact/contact.component';
import { PricesComponent } from './prices/prices.component';
import { SafePipe } from './safe.pipe';

@NgModule({
  declarations: [
    AppComponent,
    GalleryViewComponent,
    ImageDescriptionComponent,
    AboutMeComponent,
    PortfolioComponent,
    ContactComponent,
    PricesComponent,
    SafePipe
  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        MatToolbarModule,
        MatCardModule,
        FlexLayoutModule,
        MatGridListModule,
        NgxMasonryModule,
        MatIconModule,
        MatButtonModule,
        MatBottomSheetModule,
        MatListModule,
        AppRoutingModule,
        MatTooltipModule
    ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
