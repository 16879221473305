<mat-card [class.mat-elevation-z0]="true">
  <mat-card-content>
    <div class="row">
      <span class="header">{{galleryItem.title}}</span>
    </div>
    <div class="blank-row"></div>
   <div fxLayout = "row">
     <div fxFlex="60">
       <p class="description-text" [innerHtml]="galleryItem.description | safe: 'html'" >
       </p>
     </div>
     <div fxFlex="30" fxFlexOffset="10">
       <p class="client-text">
         Client: {{galleryItem.clientName}}<br>
         Role: {{galleryItem.role}}<br>
         <span [innerHtml]="galleryItem.taskDescription | safe: 'html'"></span><br>
         <span *ngIf="galleryItem.tools">Tools: {{galleryItem?.tools}}</span>
       </p>
     </div>
   </div>
  </mat-card-content>
</mat-card>
