<mat-toolbar class="app-navbar" *ngIf="!mobileView">
  <a class="logo-style" fxFlexOffset="10" (click)="openHome()">anastassija&nbsp;<span style="font-weight:600;font-size:20px;">designs</span></a>
    <a mat-flat-button style="font-weight:100; font-size:16px" class="no-hover-effect" fxFlexOffset="45" fxFlex="5" fxFlexOffset.md="35" fxFlexOffset.sm="20" routerLink="/portfolio" routerLinkActive="active">Portfolio</a>
    <a mat-flat-button style="font-weight:100; font-size:16px" class="no-hover-effect" fxFlex="5" fxFlexOffset="3" fxFlexOffset.md="2" fxFlexOffset.sm="2" routerLink="/about" routerLinkActive="active">About</a>
  <a mat-flat-button style="font-weight:100; font-size:16px" class="no-hover-effect" fxFlex="5" fxFlexOffset="2" fxFlexOffset.md="2" fxFlexOffset.sm="2" routerLink="/prices" routerLinkActive="active">Prices</a>
  <a mat-flat-button style="font-weight:100; font-size:16px" class="no-hover-effect" fxFlex="5" fxFlexOffset="2" fxFlexOffset.md="2" fxFlexOffset.sm="2" routerLink="/contact" routerLinkActive="active">Contact</a>
</mat-toolbar>
<mat-toolbar class="app-navbar" *ngIf="mobileView">
  <mat-toolbar-row>
  <span style="font-weight:100;font-size:20px;" fxFlexOffset="28">anastassija&nbsp;  </span> <span style="font-weight:600;font-size:20px;"> designs</span>
  </mat-toolbar-row>
  <mat-toolbar-row>
    <a mat-flat-button style="font-weight:100; font-size:16px" class="no-hover-effect" fxFlex="6"  fxFlexOffset="10" routerLink="/portfolio" routerLinkActive="active">Portfolio</a>
    <a mat-flat-button style="font-weight:100; font-size:16px" class="no-hover-effect" fxFlex="6" fxFlexOffset="5" routerLink="/about" routerLinkActive="active">About</a>
    <a mat-flat-button style="font-weight:100; font-size:16px" class="no-hover-effect" fxFlex="6" fxFlexOffset="2" routerLink="/prices" routerLinkActive="active">Prices</a>
    <a mat-flat-button style="font-weight:100; font-size:16px" class="no-hover-effect" fxFlex="6" fxFlexOffset="2" routerLink="/contact" routerLinkActive="active">Contact</a>
  </mat-toolbar-row>
</mat-toolbar>
<div class="route-content">
<router-outlet></router-outlet>
</div>
<div fxLayout="row">
  <div fxFlexOffset="45" fxFlexOffset.xs="21" fxFlexOffset.sm="35" fxFlexOffset.md="40" style="margin-top:30px;margin-bottom: 30px;"><span style="color:#bababa ; font-weight:100 ; font-size:12px">© 2023 Anastassija Ramamurthy. All rights reserved.</span></div>
</div>


